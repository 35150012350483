import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import Link from './Link';
import {
  SecondaryAppBar,
  SecondaryTabs,
  SecondaryTab,
} from './StyledTabs';

const singleKey = key => ({ [key]: value }) => value;

const VulnerabilityComponentTabs = ({ current: currentVulnerability, currentSystem }) => {
  const { t } = useTranslation();

  const {
    vulnerabilitiesContainer: { vulnerabilities: rawFoundVulnerabilities },
    catalog,
  } = useStaticQuery(graphql`
    query {
      # Get all farming systems & vulnerability components from catalog
      catalog: adaptationsJson {
        farming_system { id value }
        farm_vulnerability_component { id value }
      }

      # Get all vulnerability components having at least one measure
      vulnerabilitiesContainer: allAdaptationMeasures {
        vulnerabilities: group(field: fields___measure___farm_vulnerability_component) {
          fieldValue
        }
      }
    }
  `);

  /**
   * Simplify results from GraphQL group queries
   */
  const foundVulnerabilities = rawFoundVulnerabilities.map(singleKey('fieldValue'));

  const currentSystemId = catalog.farming_system.find(({ value }) => (value === currentSystem)).id;

  /**
   * Array of vulnerabilities for rendering Vulnerability tabs
   */
  const vulnerabilityLinks = catalog.farm_vulnerability_component
    // Keep only vulnerabilities from current system
    .filter(({ id }) => (id[0] === currentSystemId))
    .map(({ value: vulnerability }) => ({
      id: vulnerability,
      path: vulnerability,
      enabled: foundVulnerabilities.includes(vulnerability),
    }));

  return (
    <SecondaryAppBar position="static">
      <SecondaryTabs value={currentVulnerability}>
        {vulnerabilityLinks.map(({ id, path, enabled }) => (
          <SecondaryTab
            disabled={!enabled}
            key={id}
            label={t(id)} // i18next-extract-disable-line
            value={id}
            component={Link}
            to={`/adaptations/${currentSystem}/${path}`}
          />
        ))}
        <SecondaryTab
          label={t('Others')}
          value="others"
          component={Link}
          to={`/adaptations/${currentSystem}/others`}
        />
      </SecondaryTabs>
    </SecondaryAppBar>
  );
};

export default VulnerabilityComponentTabs;
